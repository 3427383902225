import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VFail from '@/components/VFail'
import { configurePlugins } from './plugins'
import i18n from '@/i18n'

Vue.config.productionTip = false

Vue.component('v-fail', VFail)

configurePlugins(Vue)

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App)
}).$mount('#app')
