<template>
  <div class="container">
    <b-navbar id="main-navbar" fixed-top:true>
      <template slot="brand">
        <b-navbar-item
          v-if="root !== undefined"
          tag="router-link"
          :to="{ name: 'division', params: { id: root.id } }"
        >
          <img id="navbar-logo" src="@/assets/logo_imfd_2.png" :alt="$t('navbar.logoAlt')" />
        </b-navbar-item>
      </template>

      <template slot="start">
        <b-navbar-item
          v-if="root !== undefined"
          tag="router-link"
          :to="{ name: 'division', params: { id: root.id } }"
        >
          {{ root.short_name || root.name }}
        </b-navbar-item>

        <b-navbar-item tag="router-link" :to="{ name: 'profile', query: { tab: 'divisions' } }">
          {{ $t('navbar.myMemberships') }}
        </b-navbar-item>
      </template>

      <template slot="end">
        <!-- User Options -->
        <div class="navbar-item has-dropdown is-hoverable">
          <a class="navbar-link">
            <figure class="image is-32x32 h-spaced is-inline-flex">
              <img class="is-rounded" :src="imageUrl" />
            </figure>
            <span>{{ `${person.first_name} ${person.last_name}` }}</span>
          </a>
          <div class="navbar-dropdown">
            <b-navbar-item tag="router-link" :to="{ path: '/profile' }">
              <b-icon icon="bookmark"></b-icon>
              <span>{{ $t('navbar.profile') }}</span>
            </b-navbar-item>

            <!-- <b-navbar-item id="switch-locale" @click.prevent="switchLocale">
              <b-icon icon="earth"></b-icon>
              <span>{{ $t('navbar.switchLocale') }}</span>
            </b-navbar-item> -->

            <b-navbar-item
              tag="router-link"
              :to="{ path: '/control-panel' }"
              v-if="
                person.assign_permissions_to_people || person.manage_division_information.length > 0
              "
            >
              <b-icon icon="account-cog"></b-icon>
              <span>{{ $t('navbar.controlPanel') }}</span>
            </b-navbar-item>

            <b-navbar-item id="logout-button" @click.prevent="logout">
              <b-icon icon="logout"></b-icon>
              <span>{{ $t('navbar.logout') }}</span>
            </b-navbar-item>
          </div>
        </div>
      </template>
    </b-navbar>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
import { findRoot } from '@/requests/divisions'

const { mapState, mapActions } = createNamespacedHelpers('auth')

export default {
  data() {
    return {
      root: undefined
    }
  },
  computed: {
    ...mapState(['person']),
    imageUrl() {
      const url = process.env.VUE_APP_MEDIA_URL || 'http://localhost:8001/'
      return `${url.replace(/\/$/, '')}${this.person.avatar}?rnd=${new Date().getTime()}`
    }
  },
  methods: {
    ...mapActions(['logout'])

    // switchLocale() {
    //   if (this.$i18n.locale === 'es') {
    //     this.$i18n.locale = 'en'
    //   } else {
    //     this.$i18n.locale = 'es'
    //   }
    // }
  },
  async mounted() {
    // eslint-disable-next-line
    const root = await findRoot()
    this.root = root
  }
}
</script>

<style lang="scss" scoped>
.navbar-end {
  .navbar-dropdown a.navbar-item {
    padding-right: 1.5rem;
  }
  .h-spaced {
    margin-right: 0.5rem;
    vertical-align: bottom;
  }
  .icon {
    padding-right: 10px;
  }
}
</style>
