import { requestLogout, fetchProfile } from '@/requests/auth'

export const state = {
  loggedIn: false,
  person: null,
  serverError: false
}

export const mutations = {
  logUserIn(state, person) {
    state.loggedIn = true
    state.person = person
  },
  destroySession(state) {
    state.loggedIn = false
    state.person = null
  },
  refreshProfile(state, person) {
    state.person = person
  },
  setServerError(state) {
    state.serverError = true
  }
}

export const actions = {
  logout: async ({ commit }) => {
    await requestLogout()
    const { default: router } = await import('@/router')
    router.push({ name: 'login' })
    commit('destroySession')
  },

  getProfile: async ({ commit }) => {
    try {
      const profile = await fetchProfile()
      if (profile) {
        commit('logUserIn', profile)
      } else {
        commit('destroySession')
      }
    } catch (e) {
      commit('setServerError')
    }
  }
}

export default { state, mutations, actions, namespaced: true }
