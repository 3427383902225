<template>
  <div class="home-container container">
    <h1 class="title">{{ $t('views.home.title') }}</h1>
    <h2 class="subtitle">{{ $t('views.home.subtitle') }}</h2>
  </div>
</template>

<script>
export default {
  name: 'TheHome'
}
</script>

<style lang="scss" scoped>
.home-container {
  min-height: 50vh;
  margin-top: 2vh;
}
</style>
