const messages = {
  en: {
    views: {
      divisionCreate: {
        title: 'Create a division',
        membershipTypeLabel: 'Membership Type on this institution',
        roleTypeLabel: 'Role on this division',
        adminTitle: 'Do you want to add an administrator?',
        invitationTitle: 'Invite new member',
        divisionTitle: 'Division information',
        confirm: 'Confirm',
        buttonCreate: 'Create',
        buttonPrevious: 'Previous',
        buttonNext: 'Next'
      },
      divisionDetail: {
        division: 'Division',
        subdivision: 'Subdivisions',
        addNewSubdivision: 'Add new division',
        addNewProject: 'Add new project'
      },
      membershipCreate: {
        title: 'Add a member'
      },
      myMemberships: {
        title: 'My Memberships',
        subtitle: 'Divisions with memberships'
      },
      signup: {
        title: 'Sign up here!',
        goneInvitation: 'Sorry, this invitation has expired',
        registerTitle: 'User registration'
      },
      home: {
        title: 'Welcome',
        subtitle: 'We invite you to explore the IMFD'
      },
      invitation: {
        successMsg: 'Your invitation has been sent successfully'
      },
      login: {
        title: 'Intranet IMFD',
        emailLabel: 'Email',
        passwordLabel: 'Password',
        button: 'Login',
        forgotPassword: 'Did you forget your password?',
        incorrectCredentials: 'Incorrect authentication credentials',
        failTitleMessage: 'Error logging into the system',
        failPasswordTitle: 'You must enter your email',
        failPasswordBody: 'You have to provide an email addess to recover your password',
        successModalTitle: 'We have sent you an email!',
        successModalMessage:
          'To recover your IMFD Intranet password, click on the link in the email and update your password.',
        successModalFooter: '<strong>Check your inbox</strong>'
      },
      personProfile: {
        noDivisionsWarn: 'At the moment, you are not in a division',
        noProjectsWarn: 'At the moment, you are not in a project',
        personalInformation: 'Personal Information',
        associatedDivisions: 'Associated divisions',
        associatedProjects: 'Associated projects',
        publications: 'Publications',
        updateAvatar: 'Avatar actualizado exitosamente'
      },
      editMembership: {
        title: 'Edit member',
        email: 'Email',
        rol: 'Role',
        division: 'Division',
        project: 'Project',
        cancel: 'Cancel',
        submit: 'Edit',
        warningLabel: 'This person is member of',
        successMessage: 'Membership successfully edited'
      },
      projectCreate: {
        title: 'Create  new project',
        startDate: 'Start date',
        endDate: 'End date (optional)',
        creationSuccess: 'The project was created',
        creationSuccessAction: 'View',
        createLabel: 'Create Project',
        parentDivisionLabel: 'The new project will be a direct dependant of {division}',
        projectTitle: 'Proyect information',
        buttonPrevious: 'Previous',
        buttonNext: 'Next'
      },
      permissions: {
        title: 'Manage People',
        assignButton: 'Select divisions',
        deleteButton: 'Delete user',
        managePeopleCheckbox: 'Super admin',
        seeSensitiveInfoCheckbox: 'See personal information',
        submitButton: 'Apply',
        cancelButton: 'Close',
        successMessage: 'Permissions assinged successfully',
        noRole: 'No Role',
        noRoleTitle: 'User without role',
        modalMessageSubtitle: 'This user does not have any role associated.',
        modalMessage:
          'You can assign a role in any division you manage or delete from Intranet permanently ',
        modalTitleDeleteUser: 'Delete user',
        modalMessageDeleteQuestion: 'Are you sure you want to delete this user of Intranet?',
        modalMessageDeleteUser:
          'This action will delete all the user roles and its job title held to date.',
        modalDeleteUserConfirmBotton: 'Delete',
        modalDeleteUserBackbutton: 'Back',
        successDeleteMessage: 'User deleted successfully'
      }
    },
    divisionCardDetailed: {
      members: 'Members',
      amountProjects: 'Ongoing Projects',
      button: {
        seeAll: 'See all members',
        seeLeaders: 'Show only leaders'
      },
      addPeople: 'Add people',
      subdivisions: 'Subdivisions',
      projects: 'Projects',
      addNewMember: 'Add new member',
      tags: 'Tags: '
    },
    divisionMembers: {
      title: 'Members of this division',
      noMembersInfoMsg: 'There are no members in this division'
    },
    divisionRadioSelector: {
      selectDivision: 'Select an existing division',
      newDivision: 'Create a new division',
      error: 'Invalid type of selection for divisions'
    },
    invitationForm: {
      emailLabel: 'Email',
      emailPlaceholder: 'e.g. juan@imfd.cl',
      emailAutocompleteMessage: 'Invite {email}',
      divisionLabel: 'Division',
      membershipTypeLabel: 'Membership Type',
      button: 'Send'
    },
    membershipCard: {
      title: 'Membership',
      division: 'Division',
      membershipType: 'Membership Type',
      otherMembers: 'Dependants persons'
    },
    membershipCreateForm: {
      personLabel: 'New member name or email',
      membershipTypeLabel: 'Membership in the institution',
      divisionLabel: 'The new member will be added to',
      creationErrorAction: 'Close',
      creationSuccess: 'New member added successfully',
      invitationSuccess: 'New member invited successfully',
      creationSuccessAction: 'Close',
      button: 'Send',
      makeAdmin: 'Make division administrator',
      divisionRoleTypeLabel: 'Role in this division',
      projectRoleTypeLabel: 'Role in this project',
      optionalRole: 'This user has role?'
    },
    messageError: {
      defaultTitle: 'Error',
      somethingWrong: 'Oops... Something went wrong.',
      notFound:
        'Sorry, but it seems that the page you are looking for <strong>does not exist.</strong>',
      serverError: 'Sorry, but the website is currently unavailable',
      goBack: 'Go back'
    },
    messageInfo: {
      defaultTitle: 'Information'
    },
    messageSuccess: {
      defaultTitle: 'Hooray!'
    },
    personCard: {
      title: 'Personal Information',
      idNumber: 'RUT or Passport',
      name: 'Name',
      email: 'Email',
      nationality: 'Nationality',
      button: 'Edit'
    },
    signUpForm: {
      successMsg: 'You have successfully registered',
      firstNameLabel: 'First Name',
      lastNameLabel: 'Last Name',
      idNumberLabel: 'RUT or Passport',
      nationalityLabel: 'Nationality',
      passwordLabel: 'Password',
      passwordVerificationLabel: 'Password Verification',
      button: 'Sign Up!'
    },
    navbar: {
      logoAlt: 'Millenium Institute Foundational Research on Data',
      myMemberships: 'My Memberships',
      switchLocale: 'Español por favor',
      info: 'Info',
      about: 'About',
      contact: 'Contact',
      profile: 'Profile',
      logout: 'Log out',
      controlPanel: 'Control panel'
    },
    divisionCreateForm: {
      divisionNameLabel: 'Name of the new subdivision',
      divisionShortNameLabel: 'Provide a short name',
      divisionDescriptionLabel: 'Description of this division',
      projectNameLabel: 'Name of the new project',
      projectShortNameLabel: 'Provide a short name',
      projectDescriptionLabel: 'Description of this project',
      membershipTypeLabel: 'Membership Type',
      parentDivisionLabel: 'The new division will be a direct dependant of {division}',
      creationSuccess: 'The division was created',
      creationSuccessAction: 'View',
      createLabel: 'Create Division'
    },
    personSelector: {
      emptySearch: 'No people were found with this criteria',
      dialogConfirm:
        'This email is not registered in the platform. If you wish to invited it press ok'
    },
    membersTable: {
      name: 'Name',
      rol: 'Role',
      since: 'Member since'
    },
    personEditProfile: {
      first_name: 'First name',
      last_name: 'Last name',
      cancelButton: 'Cancel',
      saveButton: 'Save',
      editedSuccess: 'Your profile has been successfully edited'
    },
    membersModal: {
      members: 'Members ({n})',
      closeButton: 'Show less',
      dialogDeleteConfirmDivison:
        'This person will be deleted from this division, if you are sure press "Ok"',
      dialogDeleteConfirmProject:
        'This person will be deleted from this project, if you are sure press "Ok"'
    },
    divisionDetailBody: {
      dialogDeleteConfirmProject: 'Are you sure to remove this member from this project?',
      dialogDeleteConfirmDivision: 'Are you sure to remove this member from this division?',
      noSubdivisions: 'No <strong>subdivisions</strong> available yet',
      noProjects: 'No <strong>projects</strong> available yet',
      noMembers: 'No <strong>Members</strong> available yet',
      showMoreButton: 'Show more',
      showLessButton: 'Show less'
    },
    editDivisionDetailHead: {
      nameDivisionInput: 'Name of this division',
      descriptionDivisionInput: 'Description of this division',
      shortNameDivisionInput: 'Short name of this division',
      nameProjectInput: 'Name of this project',
      descriptionProjectInput: 'Description of this project',
      shortNameProjectInput: 'Short name of this project',
      divisionEditedSuccess: 'Division successfully edited',
      projectEditedSuccess: 'Project successfully edited',
      deleteButton: 'Delete',
      divisionDeletedSuccess: 'Division successfully deleted',
      projectDeletedSuccess: 'Project successfully deleted'
    },
    memberInvitationForm: {
      inviteButton: 'Invite'
    },
    divisionConfirm: {
      divisionInfo: 'Division information',
      name: 'Name',
      shortName: 'Short name',
      description: 'Description',
      adminInfo: 'Admin information',
      person: 'Name or email',
      role: 'Role in this division',
      roleInProject: 'Role in this project',
      membership: 'Membership on the institution'
    },
    form: {
      fieldRequired: 'This field is required',
      endDate: 'The end date must be later than start date',
      clickToSelect: 'Click to select...'
    },
    resetPassword: {
      title: 'Password recovery',
      email: 'Email',
      password: 'New password',
      passwordConfirm: 'Password verify',
      button: 'Reset password'
    },
    permissionModal: {
      title: 'Asign permissions',
      nameDivisionTable: 'Division / Project',
      editPermissionCheckbox: 'Edit information',
      deletePermissionCheckbox: 'Delete division / project',
      addPermissionCheckbox: 'Add subdivisions / project',
      adminCheckbox: 'Admin'
    }
  },
  es: {
    views: {
      divisionCreate: {
        title: 'Agregar división',
        membershipTypeLabel: 'Cargo en esta institución',
        roleTypeLabel: 'Rol en esta división',
        adminTitle: '¿Quieres agregar un administrador?',
        invitationTitle: 'Invitar nuevo miembro',
        divisionTitle: 'Información de la división',
        confirm: 'Confirmación',
        buttonCreate: 'Agregar',
        buttonPrevious: 'Anterior',
        buttonNext: 'Siguiente'
      },
      divisionDetail: {
        division: 'División',
        subdivision: 'Subdivisiones',
        addNewSubdivision: 'Agregar división',
        addNewProject: 'Agregar proyecto'
      },
      membershipCreate: {
        title: 'Agregar persona'
      },
      myMemberships: {
        title: 'Mis divisiones',
        subtitle: 'Divisiones a las que perteneces'
      },
      signup: {
        title: 'Regístrate aquí',
        goneInvitation: 'Lo sentimos, esta invitación ha expirado',
        registerTitle: 'Registro de usuario'
      },
      home: {
        title: 'Te damos la bienvenida',
        subtitle: 'Te invitamos a explorar el IMFD'
      },
      invitation: {
        successMsg: 'Tu invitación se ha enviado exitosamente'
      },
      login: {
        title: 'Intranet IMFD',
        emailLabel: 'Correo electrónico',
        passwordLabel: 'Contraseña',
        button: 'Iniciar sesión',
        forgotPassword: '¿Olvidaste tu contraseña?',
        incorrectCredentials: 'Credenciales incorrectas',
        failTitleMessage: 'Error al ingresar al sistema',
        failPasswordTitle: 'Ingresa un correo electrónico válido',
        failPasswordBody: 'Ingresa un correo electrónico para poder recuperar tu contraseña',
        successModalTitle: 'Te hemos enviado un correo',
        successModalMessage:
          'Para recuperar tu contraseña de Intranet IMFD entra al enlace del correo y actualiza tu contraseña',
        successModalFooter: '<strong>Revisa tu bandeja de entrada</strong>'
      },
      personProfile: {
        noDivisionsWarn: 'Por el momento no estás en ninguna división',
        noProjectsWarn: 'Por el momento no estás en ningún proyecto',
        personalInformation: 'Mi información personal',
        personalInformationSemiPublic: 'Información personal',
        associatedDivisions: 'Mis divisiones',
        associatedDivisionsSemiPublic: 'Divisiones asociadas',
        associatedProjects: 'Mis proyectos',
        associatedProjectsSemiPublic: 'Proyectos asociados',
        publications: 'Mis publicaciones',
        publicationsSemiPublic: 'Publicaciones',
        updateAvatar: 'Avatar actualizado exitosamente'
      },
      editMembership: {
        title: 'Editar integrante',
        email: 'Correo electrónico',
        rol: 'Rol',
        division: 'División',
        project: 'Proyecto',
        cancel: 'Cancelar',
        submit: 'Editar',
        warningLabel: 'Esta persona es integrante de',
        successMessage: 'Membresía editada exitosamente '
      },
      projectCreate: {
        title: 'Agregar proyecto',
        startDate: 'Fecha de inicio',
        endDate: 'Fecha de término (opcional)',
        creationSuccess: 'El proyecto fue agregado',
        creationSuccessAction: 'Ver',
        createLabel: 'Agregar proyecto',
        parentDivisionLabel: 'El proyecto será agregado como parte de {division}',
        projectTitle: 'Información del proyecto',
        buttonPrevious: 'Anterior',
        buttonNext: 'Siguiente'
      },
      permissions: {
        title: 'Administrar Personas',
        assignButton: 'Seleccionar divisiones',
        deleteButton: 'Borrar usuario',
        managePeopleCheckbox: 'Super administrador',
        seeSensitiveInfoCheckbox: 'Ver información personal',
        submitButton: 'Aplicar',
        cancelButton: 'Cerrar',
        successMessage: 'Permisos asignados con éxito',
        noRole: 'Sin Rol',
        noRoleTitle: 'Usuario sin rol',
        modalMessageSubtitle: 'Este usuario no tiene un rol asociado.',
        modalMessage:
          'Puede asignarle un rol desde una división que administre, o borrarlo de la Intranet de forma permanente',
        modalTitleDeleteUser: 'Borrar usuario',
        modalMessageDeleteQuestion: '¿Estás seguro que quieres borrar al usuario de la Intranet?',
        modalMessageDeleteUser: 'Con esta acción se borrarán todos los roles y cargos a la fecha.',
        modalDeleteUserConfirmBotton: 'Borrar',
        modalDeleteUserBackbutton: 'Volver',
        successDeleteMessage: 'Usuario eliminado exitosamente'
      }
    },
    divisionCardDetailed: {
      members: 'Personas',
      amountProjects: 'Proyectos',
      button: {
        seeAll: 'Ver a todas las personas',
        seeLeaders: 'Mostrar solo líderes'
      },
      addPeople: 'Agregar',
      subdivisions: 'Subdivisiones',
      projects: 'Proyectos',
      addNewMember: 'Agregar persona',
      tags: 'Etiquetas: '
    },
    divisionMembers: {
      title: 'Personas en esta división',
      noMembersInfoMsg: 'Esta división no tiene personas'
    },
    divisionRadioSelector: {
      selectDivision: 'Seleccionar una división existente',
      newDivision: 'Agregar una nueva división',
      error: 'El tipo de división seleccionado no es válido'
    },
    invitationForm: {
      emailLabel: 'Correo electrónico',
      emailPlaceholder: 'ej. juan@imfd.cl',
      emailAutocompleteMessage: 'Invitar a {email}',
      divisionLabel: 'División',
      button: 'Enviar'
    },
    membershipCard: {
      title: 'División',
      division: 'Nombre',
      membershipType: 'Tipo de participación',
      otherMembers: 'Personas a cargo'
    },
    membershipCreateForm: {
      personLabel: 'Nombre o correo electrónico de la persona',
      membershipTypeLabel: 'Cargo en la institución',
      divisionLabel: 'La persona será agregada a',
      creationErrorAction: 'Cerrar',
      creationSuccess: 'La persona fue agregada exitosamente',
      invitationSuccess: 'La persona fue invitada exitosamente',
      creationSuccessAction: 'Cerrar',
      button: 'Agregar',
      makeAdmin: 'Hacer administrador de la división',
      divisionRoleTypeLabel: 'Rol en esta división',
      projectRoleTypeLabel: 'Rol en este proyecto',
      optionalRole: '¿Este usuario tiene rol?'
    },
    messageError: {
      defaultTitle: 'Error',
      somethingWrong:
        'Ha ocurrido un error inesperado. Por favor, intenta recargar la página o contacta al administrador de la plataforma.',
      notFound: 'Lo sentimos, pero parece que la página que buscas <strong>no existe.</strong>',
      serverError:
        'Lo sentimos, pero el sitio no está disponible en estos momentos.<br>Por favor, inténtalo más tarde.',
      goBack: 'Volver'
    },
    messageInfo: {
      defaultTitle: 'Información'
    },
    messageSuccess: {
      defaultTitle: 'Acción realizada exitosamente'
    },
    personCard: {
      title: 'Información personal',
      idNumber: 'RUT o pasaporte',
      name: 'Nombre',
      email: 'Correo electrónico',
      nationality: 'País de origen',
      button: 'Editar'
    },
    signUpForm: {
      successMsg: 'Te has registrado correctamente',
      firstNameLabel: 'Nombre',
      lastNameLabel: 'Apellido',
      idNumberLabel: 'RUT o pasaporte',
      nationalityLabel: 'País de origen',
      passwordLabel: 'Contraseña',
      passwordVerificationLabel: 'Confirmar contraseña',
      button: 'Registrarse'
    },
    navbar: {
      logoAlt: 'Instituto Milenio Fundamentos de los Datos',
      myMemberships: 'Mis divisiones',
      switchLocale: 'English please',
      about: 'Somos',
      contact: 'Contacto',
      profile: 'Perfil',
      logout: 'Cerrar sesión',
      controlPanel: 'Panel de control'
    },
    divisionCreateForm: {
      divisionNameLabel: 'Nombre de la división',
      divisionShortNameLabel: 'Nombre corto',
      divisionDescriptionLabel: 'Descripción',
      projectNameLabel: 'Nombre del proyecto',
      projectShortNameLabel: 'Nombre corto',
      projectDescriptionLabel: 'Descripción',
      personLabel: 'Encargado de la nueva subdivisión',
      membershipTypeLabel: 'Rol',
      parentDivisionLabel: 'La nueva división será agregada como parte de {division}',
      creationSuccess: 'La división fue agregada',
      creationSuccessAction: 'Ver',
      createLabel: 'Agregar división'
    },
    personSelector: {
      emptySearch: 'No se encontraron personas bajo el criterio de búsqueda',
      dialogConfirm:
        'Esta persona no está registrada en la plataforma. ¿Quieres enviarle una invitación?',
      send: 'Enviar',
      cancel: 'Cancelar'
    },
    membersTable: {
      name: 'Nombre',
      rol: 'Rol',
      since: 'Miembro desde'
    },
    personEditProfile: {
      first_name: 'Nombre',
      last_name: 'Apellido',
      cancelButton: 'Cancelar',
      saveButton: 'Guardar',
      editedSuccess: 'Tu perfil ha sido editado exitosamente'
    },
    membersModal: {
      members: 'Integrantes ({n})',
      closeButton: 'Ver menos',
      dialogDeleteConfirmDivison: '¿Quieres eliminar a esta persona de la división?',
      dialogDeleteConfirmProject: '¿Quieres eliminar a esta persona del proyecto?',
      confirm: 'Eliminar',
      cancel: 'Cancelar'
    },
    divisionDetailBody: {
      dialogDeleteConfirmProject: '¿Confirmas la eliminación de la persona de este proyecto?',
      dialogDeleteConfirmDivision: '¿Confirmas la eliminación de la persona de esta división?',
      confirm: 'Eliminar',
      cancel: 'Cancelar',
      lastRoleConfirmation: 'Esta persona no tiene roles en otras divisiones o proyectos.',
      lastRoleDivision:
        'Al desvincularla de esta división, el administrador de la plataforma será notificado para desactivar su cuenta.',
      lastRoleProject:
        'Al desvincularla de este proyecto, el administrador de la plataforma será notificado para desactivar su cuenta.',
      noSubdivisions: 'Aún no existen <strong>subdivisiones</strong> disponibles',
      noProjects: 'Aún no existen <strong>proyectos</strong> disponibles',
      noMembers: 'Aún no existen <strong>miembros</strong> disponibles',
      showMoreButton: 'Ver todos',
      showLessButton: 'Ver menos'
    },
    editDivisionDetailHead: {
      nameDivisionInput: 'Nombre de la división',
      descriptionDivisionInput: 'Descripción de la división',
      shortNameDivisionInput: 'Nombre corto de la división',
      nameProjectInput: 'Nombre del proyecto',
      descriptionProjectInput: 'Descripción del proyecto',
      shortNameProjectInput: 'Nombre corto del proyecto',
      divisionEditedSuccess: 'División editada exitosamente',
      projectEditedSuccess: 'Proyecto editado exitosamente',
      deleteButton: 'Eliminar',
      divisionDeletedSuccess: 'División eliminada exitosamente',
      projectDeletedSuccess: 'Proyecto eliminado exitosamente'
    },
    memberInvitationForm: {
      inviteButton: 'Invitar'
    },
    divisionConfirm: {
      divisionInfo: 'Información de la división',
      name: 'Nombre',
      shortName: 'Nombre corto',
      description: 'Descripción',
      adminInfo: 'Información del administrador',
      person: 'Nombre o correo electrónico',
      role: 'Rol en la división',
      roleInProject: 'Rol en el proyecto',
      membership: 'Cargo en la institución'
    },
    form: {
      fieldRequired: 'Campo obligatorio',
      endDate: 'La fecha de término debe ser posterior a la fecha de inicio',
      clickToSelect: 'Seleccionar...'
    },
    resetPassword: {
      title: 'Recuperación de contraseña',
      email: 'Correo eléctronico',
      password: 'Nueva contraseña',
      passwordConfirm: 'Confirmar contraseña',
      button: 'Restablecer contraseña'
    },
    permissionModal: {
      title: 'Asignar permisos',
      nameDivisionTable: 'División / Proyecto',
      editPermissionCheckbox: 'Editar información',
      deletePermissionCheckbox: 'Eliminar división/ proyecto',
      addPermissionCheckbox: 'Agregar subdivisiones / proyectos',
      adminCheckbox: 'Administrar'
    }
  }
}

export { messages }
