import AppError from './AppError'

export default class FormValidationError extends AppError {
  constructor(what) {
    super(what)
    this.name = this.constructor.name

    this.report = what
  }
}
