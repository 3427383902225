import { defaultMeta } from '@/router'

export default (store) => {
  return (to, _, next) => {
    let meta = {
      ...defaultMeta,
      ...to.meta
    }
    if (meta.auth && !store.state.auth.loggedIn) {
      store.dispatch('auth/getProfile').then(() => {
        if (!store.state.auth.loggedIn) {
          return next({ name: 'login', query: { next: encodeURIComponent(to.path) } })
        }
        return next()
      })
    } else if (to.name === 'login' && store.state.auth.loggedIn) {
      return next({ name: 'home', replace: true })
    } else if (to.name === 'login' && !store.state.auth.loggedIn) {
      store.dispatch('auth/getProfile').then(() => {
        if (!store.state.auth.loggedIn) {
          return next()
        }
        return next({ name: 'home', replace: true })
      })
    } else {
      return next()
    }
  }
}
