<template>
  <div v-if="!serverError">
    <the-navbar v-if="userLoggedIn" />
    <router-view :key="$route.path" />
  </div>
  <server-error v-else />
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
import TheNavbar from '@/components/TheNavbar'
import NotLoggedInError from '@/errors/NotLoggedInError'
import ServerError from '@/components/ServerError'

const { mapState } = createNamespacedHelpers('auth')

export default {
  computed: {
    ...mapState({
      userLoggedIn: 'loggedIn',
      serverError: 'serverError'
    })
  },
  components: {
    TheNavbar,
    ServerError
  },
  methods: {
    interceptError(err) {
      if (err instanceof NotLoggedInError) {
        this.$store.dispatch('auth/logout')
        return false
      } else {
        this.$buefy.toast.open({
          message: 'Oops... Something went wrong.',
          type: 'is-danger',
          position: 'is-top'
        })
      }
    }
  },
  watch: {
    userLoggedIn(loggedIn) {
      if (loggedIn) {
        const next = this.$route.query.next
        if (next) {
          this.$router.push(next)
        }
      } else {
        return this.$router.push({ name: 'login' })
      }
    }
  },

  errorCaptured(err) {
    return this.interceptError(err)
  }
}
</script>

<style lang="scss">
// SCSS suggests switching @import to @use, but that doesn't work
@import 'assets/stylesheets/bulma-config.scss';
@import '~@mdi/font/css/materialdesignicons.css';
@import 'assets/stylesheets/util.scss';
</style>
