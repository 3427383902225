import AppError from '@/errors/AppError'
import axios from './index'
import { anonAxios } from './index'

export async function requestLogout() {
  await axios.delete('api/sessions')
}

export async function fetchProfile() {
  const response = await anonAxios.get('api/people/profile')
  if (response.status === 200) {
    return response.data
  }
  return false
}

export async function userLogIn(credentials) {
  const response = await anonAxios.post('api/sessions', credentials)
  if (response.status === 204) {
    return response
  } else if (response.status === 401) {
    throw new AppError(response.data.detail)
  } else if (response.status === 422) {
    throw new AppError(response.data.detail[0].msg)
  }
  throw new Error('Unexpected status code returned')
}
