import axios from 'axios'
import NotLoggedInError from '@/errors/NotLoggedInError'

const axiosConstructor = {
  baseURL: '/',
  withCredentials: process.env.NODE_ENV == 'development',
  validateStatus: (status) => status < 500,
  xsrfCookieName: 'csrftoken',
  xsrfHeaderName: 'X-CSRFToken'
}

const appAxios = axios.create(axiosConstructor)

appAxios.interceptors.response.use(
  (response) => {
    if (response.status === 401) {
      throw new NotLoggedInError("You're not logged in")
    }

    return response
  },
  (error) => Promise.reject(error)
)
const anonAxios = axios.create(axiosConstructor)

appAxios.interceptors.request.use(
  async (config) => {
    const hasCsrfToken = !!document.cookie
      .split(';')
      .map((cookie) => cookie.trim())
      .find((cookie) => cookie.startsWith('csrftoken='))

    if (!hasCsrfToken) {
      await axios.get('/api/csrf')
    }
    return config
  },
  (error) => Promise.reject(error)
)

anonAxios.interceptors.request.use(
  async (config) => {
    const hasCsrfToken = !!document.cookie
      .split(';')
      .map((cookie) => cookie.trim())
      .find((cookie) => cookie.startsWith('csrftoken='))

    if (!hasCsrfToken && config) {
      await axios.get('/api/csrf')
    }
    return config
  },
  (error) => Promise.reject(error)
)

export default appAxios
export { anonAxios }
