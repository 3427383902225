<script>
export default {
  props: {
    error: {
      required: true,
      validator(value) {
        return value instanceof Error
      }
    }
  },
  //eslint-disable-next-line
  render() {
    throw this.error
  }
}
</script>
