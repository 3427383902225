import Vue from 'vue'
import { z, ZodError } from 'zod'
import i18n from '@/i18n'

const divisionInformationSchema = z.object({
  name: z.string(),
  short_name: z.string(),
  description: z.string()
})

const divisionAdminstratorSchema = z
  .object({
    person_id: z.string().or(z.number()).optional() | null,
    role_type_id: z.number().optional(),
    membership_type_id: z.number().optional()
  })
  .superRefine((val, ctx) => {
    if (typeof val.person_id === 'string' && !val.membership_type_id) {
      ctx.addIssue({
        message: i18n.t('form.fieldRequired'),
        path: ['membership_type_id'],
        code: z.ZodIssueCode.custom
      })
    }
    if (typeof val.person_id === 'number' && !val.role_type_id) {
      ctx.addIssue({
        message: i18n.t('form.fieldRequired'),
        path: ['role_type_id'],
        code: z.ZodIssueCode.custom
      })
    }
  })

const getDefaultState = () => {
  return {
    activeStep: 0,
    divisionInformation: {},
    adminInformation: {},
    errors: {},
    types: {}
  }
}

const steps = {
  DIVSION_STEP: 0,
  ADMIN_STEP: 1
}

// initial state
const state = getDefaultState()

export const mutations = {
  nextStep(state, { value }) {
    state.activeStep = value
  },
  updateInformationValue(state, { field, value }) {
    Vue.set(state.divisionInformation, field, value)
    Vue.delete(state.types, field)
    Vue.delete(state.errors, field)
  },
  updateAdministratorValue(state, { field, value }) {
    Vue.set(state.adminInformation, field, value)
    Vue.delete(state.types, field)
    Vue.delete(state.errors, field)
  },
  resetStore(state) {
    Object.assign(state, getDefaultState())
  },
  addErrors(state, { errors }) {
    errors.map((field) => {
      Vue.set(state.types, field, 'is-danger')
      Vue.set(state.errors, field, i18n.t('form.fieldRequired'))
    })
  },
  validateForm(state) {
    try {
      switch (state.activeStep) {
        case steps.DIVSION_STEP:
          divisionInformationSchema.parse(state.divisionInformation)
          break

        case steps.ADMIN_STEP:
          divisionAdminstratorSchema.parse(state.adminInformation)
          break
      }
    } catch (error) {
      if (error instanceof ZodError) {
        error.issues.map((err) => {
          Vue.set(state.types, err.path[0], 'is-danger')
          Vue.set(state.errors, err.path[0], i18n.t('form.fieldRequired'))
        })
      }
    }
  },
  onValidationError(state, { error }) {
    const errorDetail = error.report?.detail || []
    if (Array.isArray(errorDetail)) {
      errorDetail.forEach((error) => {
        const field = error.loc[error.loc.length - 1]
        Vue.set(state.types, field, 'is-danger')
        Vue.set(state.errors, field, error.msg)
      })
    }
  }
}

export const actions = {
  nextStep: ({ commit }) => {
    commit('nextStep')
  }
}

export default { state, mutations, actions, namespaced: true }
