import Buefy from 'buefy'
import { Promised } from 'vue-promised'
import router from './router'

export function configurePlugins(vueInstance) {
  vueInstance.use(Buefy)
  vueInstance.component('v-promised', Promised)
  if (process.env.VUE_APP_MATOMO_SITE_ID) {
    import('vue-matomo').then(({ default: VueMatomo }) => {
      vueInstance.use(VueMatomo, {
        host: '//analitica.ditt.cl/',
        siteId: process.env.VUE_APP_MATOMO_SITE_ID,
        trackerFileName: 'matomo',
        router,
        enableLinkTracking: true,
        requireConsent: false,
        trackInitialView: true,
        disableCookies: true,
        requireCookieConsent: false,
        enableHeartBeatTimer: false,
        trackSiteSearch: false
      })
    })
  }
}
