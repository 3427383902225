import { defaultMeta } from '@/router'

export default (to, _, next) => {
  let meta = {
    ...defaultMeta,
    ...to.meta
  }

  document.title = meta.title
  next()
}
