import Vue from 'vue'
import Vuex from 'vuex'
import auth from './auth'
import division from './division-form'
import project from './project-form'

Vue.use(Vuex)

export const baseStore = {
  modules: {
    auth,
    division,
    project
  }
}

const store = new Vuex.Store({
  ...baseStore
})

export default store
